import * as $ from "jquery";

export class ApiClient {
    static inviteContactByEmail(email: string, callback: (response: IApiResponse<IContact>) => void) {
        this.post<IContact>("Contacts", { username: email }, callback)
    }
    static deleteContact(email:string, callback: (response:IApiResponse<void>)=>void){
        this.delete<void>("Contacts",{username:email},callback);
    }
    static peerNetwork(status: IPeerNetworkStatus, callback: (response: IApiResponse<string>) => void) {
        this.post<string>("PeerNetwork", status, callback);
    }
    static contacts(request: IContactRequest, callback: (response: IApiResponse<IContact[]>) => void) {
        this.get<IContact[]>("Contacts", request, callback);
    }
    static get<T>(path: string, data: any, callback: (response: IApiResponse<T>) => void) {
        this.call(path, "GET", data, callback);
    }
    static post<T>(path: string, data: any, callback: (response: IApiResponse<T>) => void) {
        this.call(path, "POST", JSON.stringify(data), callback);
    }
    static delete<T>(path: string, data: any, callback: (response: IApiResponse<T>) => void) {
        this.call(path, "DELETE", JSON.stringify(data), callback);
    }
    static call<T>(path: string, method: string, data: any, callback: (response: IApiResponse<T>) => void) {
        $.ajax({
            url: '/api/' + path,
            method: method,
            data: data,
            contentType: 'application/json',
            dataType:'json',
            success: (data) => {
                callback({ success: true, data: data });
            },
            error: (jqxhr, error, message) => {
                callback({
                    success: false,
                    error: {
                        statusCode: jqxhr.status,
                        statusText: jqxhr.statusText,
                        exception: jqxhr.responseJSON as IExceptionInfo
                    }
                });
            }
        })
    }
}
export interface IApiResponse<T> {
    success: boolean;
    data?: T;
    error?: IErrorDetails;
}
export interface IExceptionInfo {
    type: string;
    message: string;
    errorCode?: ApiErrorCodes;
}
export interface IErrorDetails {
    statusCode: number;
    statusText: string;

    exception: IExceptionInfo;
}
export interface IPeerNetworkStatus {
    peerId: string;
    operation: PeerOperation;
}
export enum PeerOperation {
    None = 0,
    Connect = 1,
    Disconnect = 2,
    Reconnect = 3
}
export enum ApiErrorCodes {
    peerNetworkAlreadyConnected = 1001
}
export enum ContactStatus {
    Requested = 1,
    Received = 2,
    Confirmed = 3
}
export enum ContactFilter {
    All = 0,
    Requested = ContactStatus.Requested,
    Received = ContactStatus.Received,
    Confirmed = ContactStatus.Confirmed,
}
export interface IPagedRequest {
    offset: number;
    limit: number;
}
export interface IContactRequest extends IPagedRequest {
    filter: ContactFilter;
}
export interface IContact {
    userId: string;
    username: string;
    displayName: string;
    status: ContactStatus;
    peerId: string;
}
export interface IPublicScenarioStat{
    name:string;
    noOfCompleted:number;
    noOfStarted:number;
}
export interface IPublicGameStat {
    interval:string;
    scenarios:IPublicScenarioStat[];
}