import * as React from "react";
import * as ReactDOM from "react-dom";
import { ApiClient, IPublicGameStat, IApiResponse } from "../gloomhaven/ApiClient";

interface IPublicGameStatsState {
    gameStats: IApiResponse<IPublicGameStat>;
}

export class PublicGameStatsComponent extends React.Component<any, IPublicGameStatsState> {

    constructor(props:any) {
        super(props);
        this.state = { gameStats: null };
    }
    componentDidMount(){
        this.loadStats();
    }
    loadStats() {
        ApiClient.get<IPublicGameStat>("publicstats", null, (r) => {
            this.setState({gameStats:r});
        })
    }
    render() {
        if(this.state.gameStats) {
            if(!this.state.gameStats.success) {
                return (<div>Error loading stats {this.state.gameStats.error.statusText}</div>);
            }
            const statRows = this.state.gameStats.data.scenarios.map((s,i)=>{
                return (<tr key={i}><td>{s.name}</td><td>{s.noOfStarted}</td><td>{s.noOfCompleted}</td></tr>);
            });
            return (<div>
                <span>Number of scenarios played the last 7 days</span>
                <table className="table table-condensed table-striped table-content">
                <thead><tr><th>Scenario</th><th>Started</th><th>Completed</th></tr></thead>
                <tbody>{statRows}</tbody>
                </table>
            </div>);
        }
        return (<i className="fa fa-spinner fa-spinning"></i>);
    }
    static init(id: string, props: any) {
        ReactDOM.render(
            <PublicGameStatsComponent {...props} />,
            document.getElementById(id)
        );
    }
}
const w = window as any;
w.PublicGameStats = PublicGameStatsComponent;